<template>
  <admin>
    <page-header></page-header>
    <v-stepper alt-labels v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Batch Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Upload File
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3">
        Build Message
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Review </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- STEP 1 -->
        <v-stepper-content step="1">
          <v-card style="border: 0" class="mb-8">
            <v-card-title>Batch Information</v-card-title>
            <v-card-text>
              <v-form ref="info_form" v-model="valid.info_form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.name"
                      label="Text Blast Name"
                      class="dt-text-field"
                      outlined
                      :rules="[(v) => !!v || 'Name is required.']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="form.description"
                      outlined
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="survey"
                      :items="surveys"
                      label="Survey"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-object
                      :rules="[(v) => !!v || 'Survey is required.']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="form.sending_method"
                      :items="sendingMethods"
                      label="Sending method"
                      item-text="label"
                      item-value="value"
                      outlined
                      :rules="[(v) => !!v || 'Sending method is required.']"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-btn large class="mr-3" color="primary" @click="goToUploadStep()">
            Continue
          </v-btn>
        </v-stepper-content>

        <!-- STEP 2-->
        <v-stepper-content step="2">
          <v-card style="border: 0" class="p-3 mb-3">
            <v-card-title>File Information</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-form ref="file_form" v-model="valid.file_form" lazy-validation>
                    <v-file-input
                      label="Upload File"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      outlined
                      hide-details
                      :rules="rules.file"
                      v-model="file"
                    >
                    </v-file-input>
                    <br />
                    <p>
                      Click <a :href="sampleFileUrl" download>here</a> to the download
                      sample file.
                    </p>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <template v-if="smsBlastUpload.file_uploaded && smsBlastUpload.file_uploaded.errors.length">
                    <div>
                      <div style="overflow-y:auto;max-height:300px;">
                        <template v-for="(error, key) in smsBlastUpload.file_uploaded.errors">
                          <v-alert type="error" :key="key">
                            {{ error }}
                          </v-alert>
                        </template>
                      </div>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-btn large class="mr-3" color="grey lighten-1" @click="step--">
            Back
          </v-btn>

          <v-btn :loading="loadingUpload" :disabled="loadingUpload" large class="mr-3" color="primary" @click="goToMessageStep()">
            Continue
          </v-btn>
        </v-stepper-content>

        <!-- STEP 3 -->
        <v-stepper-content step="3">
          <v-card style="border: 0" class="mb-8">
            <v-card-title>Message & Schedule Information</v-card-title>
            <v-card-text>
              <v-form ref="message_form" v-model="valid.message_form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-chip-group class="mb-2">
                      <v-chip
                        v-for="(item, index) in smsShortCodeList"
                        :key="index"
                        @click="insertTextToMessage(item)"
                      >
                        {{ item }}
                      </v-chip>
                    </v-chip-group>
                    <v-textarea
                      ref="inp_sms_message"
                      counter
                      outlined
                      v-model="message"
                      :value="message"
                      label="Write your message"
                      :rules="[(v) => !!v || 'Message is required.']"
                      maxlength="750"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" v-if="form.sending_method === 'viber'">
                    <div class="pa-4" style="border:1px solid #d2ddec; border-radius: 8px;">
                      <div class="body-2 mb-4 font-weight-bold">Viber</div>
                      <v-select
                        v-model="viberSendType"
                        :items="viberSendTypes"
                        label="Type"
                        item-text="label"
                        item-value="value"
                        outlined
                        return-object
                        :rules="[(v) => !!v || 'Send type is required.']"
                      ></v-select>
                      <template v-if="viberSendType">
                        <template v-if="viberSendType.value === 'video'">
                          <v-text-field
                            v-model="form.viber_video_url"
                            label="Video URL"
                            class="dt-text-field"
                            outlined
                            :rules="[(v) => !!v || 'Viber video URL is required.']"
                          ></v-text-field>
                          <v-text-field
                            v-model="form.viber_video_duration"
                            label="Video duration"
                            class="dt-text-field"
                            outlined
                            type="number"
                            max="600"
                            hint="Value must be in seconds and max duration of 600 seconds."
                            persistent-hint
                            :rules="[(v) => !!v || 'Viber video duration is required.']"
                          ></v-text-field>
                        </template>
                      </template>
                      <template v-if="viberSendType">
                        <v-file-input
                          v-if="!['text', '', undefined].includes(viberSendType.value)"
                          :label="viberFileInputAttrs ? viberFileInputAttrs.label : ''"
                          outlined
                          :hint="viberFileInputAttrs ? viberFileInputAttrs.hint : ''"
                          :disabled="!viberFileInputAttrs"
                          :accept="viberFileInputAttrs ? viberFileInputAttrs.accept : ''"
                          persistent-hint
                          :rules="rules.file"
                          v-model="viberFile"
                          class="mb-5"
                        >
                        </v-file-input>
                      </template>
                      <v-text-field
                        v-model="form.viber_button_label"
                        label="Button label"
                        class="dt-text-field"
                        hint="The label of the button that will appear on the Viber message"
                        persistent-hint
                        outlined
                        :rules="[(v) => !!v || 'Viber button label is required.']"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="sendType"
                      :items="sendOptions"
                      label="Send Now or Later"
                      item-text="label"
                      item-value="value"
                      outlined
                      return-object
                      :rules="[(v) => !!v || 'Send type is required.']"
                      @change="form.scheduled_at = null"
                    ></v-select>

                    <div class="d-flex" v-if="sendType && sendType.value === 'later'">
                      <v-text-field
                        v-model="form.scheduled_at"
                        type="datetime-local"
                        label="Scheduled Date"
                        class="dt-text-field"
                        outlined
                        :rules="[(v) => !!v || 'Scheduled At is required.']"
                      ></v-text-field>
                    </div>

                    <v-select 
                      v-show="sendType && sendType.value === 'dealer_send'"
                      v-model="dealer"
                      :items="dealers"
                      item-text="text"
                      item-value="value"
                      label="Dealers"
                      outlined
                      return-object
                    ></v-select>

                    <v-text-field 
                        v-if="sendType && sendType.value === 'dealer_send'"
                        v-model="form.deadline_at"
                        type="datetime-local"
                        label="Deadline"
                        class="dt-text-field"
                        outlined
                      ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-btn large class="mr-3" color="grey lighten-1" @click="step--">
            Back
          </v-btn>

          <v-btn large class="mr-3" color="primary" @click="goToReviewStep()">
            Continue
          </v-btn>
        </v-stepper-content>

        <!-- STEP 4 -->
        <v-stepper-content step="4">
          <v-card style="border: 0" class="mb-12">
            <v-simple-table>
              <tbody>
                <tr v-if="form">
                  <td class="font-weight-bold">{{ "Text Blast Name" }}</td>
                  <td>{{ form.name }}</td>
                </tr>
                <tr v-if="survey">
                  <td class="font-weight-bold">{{ "Survey" }}</td>
                  <td>{{ survey.name }}</td>
                </tr>
                <tr v-if="form">
                  <td class="font-weight-bold">{{ "Sending method" }}</td>
                  <td class="text-uppercase">{{ form.sending_method }}</td>
                </tr>
                <tr v-if="dealer && sendType.value === 'dealer_send'">
                  <td class="font-weight-bold">{{ "Dealer" }}</td>
                  <td>{{ dealer.text }}</td>
                </tr>
                <tr v-if="smsBlastUpload && smsBlastUpload.file_uploaded">
                  <td class="font-weight-bold">{{ "No. of Recipients" }}</td>
                  <td>{{ smsBlastUpload.file_uploaded.row_count }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Message" }}</td>
                  <td>{{ message }}</td>
                </tr>
                <tr v-if="sendType">
                  <td class="font-weight-bold">{{ "Schedule" }}</td>
                  <td>{{ sendType.label }}</td>
                </tr>
                <tr v-if="infobipSenderDetails && infobipSender">
                  <td class="font-weight-bold">{{ "Sender ID" }}</td>
                  <td>{{ infobipSender.sender_id }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-btn large class="mr-3" color="grey lighten-1" @click="step--">
            Back
          </v-btn>

          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            :loading="loadingSave"
            :disabled="loadingSave"
            @click="submit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </admin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      sampleFileUrl:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/survey-sms-blast-admin.xlsx",
      step: 1,
      valid: {
        info_form: false,
        file_form: false,
        message_form: false
      },
      rules: {
        file: [
          (v) => !!v || "File is required",
          (v) => (v && v.size > 0) || "File is required",
        ]
      },
      message: "",
      allDealersSelected: false,
      form: {
        survey_id: null,
        dealer_id: null,
        name: null,
        description: null,
        message: '',
        type: null,
        scheduled_at: null,
        deadline_at: null,
        file_id: null,
        sending_method: 'viber',
        viber_button_label: 'Start Survey',
        viber_video_duration: null,
        viber_message_type: null,
        viber_video_url: null
      },
      file: null,
      viberFile: null,
      sendOptions: [
        {
          label: 'Dealer To Send',
          value: 'dealer_send'
        },
        {
          label: 'Now',
          value: 'now'
        },
        {
          label: 'Later',
          value: 'later'
        },
      ],
      survey: null,
      sendType: null,
      viberSendType: null,
      dealer: null,
      loadingSave: false,
      loadingUpload: false,
      infobipSenderDetails: null,
      infobipSender: null,
      sendingMethods: [
        {
          label: 'Viber',
          value: 'viber'
        },
        {
          label: 'SMS',
          value: 'sms'
        }
      ],
      viberSendTypes: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Image',
          value: 'image'
        },
        // {
        //   label: 'File',
        //   value: 'file'
        // },
        // {
        //   label: 'Video',
        //   value: 'video'
        // },
      ],
    };
  },
  watch: {
    dealer: {
      handler: async function (newValue, oldValue) {
        this.infobipSenderDetails = await this.getInfobipSenderDetails({
          dealer_id: newValue?.value,
          with_default: true
        })
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      surveys: "survey/GET_SURVEY",
      dealers: "dealer/GET_DEALERS",
      smsBlastUpload: "surveySmsBlasts/GET_SMS_BLAST_UPLOAD"
    }),
    smsShortCodeList() {
      const defaultShortCodes = [
        'survey_name',
        'survey_url'
      ];

      return this.smsBlastUpload?.file_uploaded?.headers ? [...defaultShortCodes, ...this.smsBlastUpload.file_uploaded.headers] : defaultShortCodes;
    },
    viberFileInputAttrs() {
      if (this.viberSendType?.value === 'video') {
        return {
          label: 'Upload video thumbnail',
          hint: 'Recommended resolution (800 px X 800 px)',
          accept: 'image/png, image/jpg, image/jpeg'
        }
      }

      if (this.viberSendType?.value === 'image') {
        return {
          label: 'Upload image',
          hint: 'Recommended resolution (800 px X 800 px)',
          accept: 'image/png, image/jpg, image/jpeg'
        }
      }

      if (this.viberSendType?.value === 'file') {
        return {
          label: 'Upload file',
          hint: '',
          accept: 'doc, .docx, .rtf, .dot, .dotx, .odt ,.odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx'
        }
      }

      return null
    }
  },
  methods: {
    ...mapActions({
      getSurveys : "survey/getSurveys",
      getDealers: "dealer/getDealers2",
      uploadSurveySmsBlast: "surveySmsBlasts/uploadSurveySmsBlast",
      save: "surveySmsBlasts/save",
      getInfobipSenderDetails: "infobipAccount/getInfobipSenderDetails"
    }),
    async uploadSurveyRecipients () {
      this.loadingUpload = true;

      let formData = new FormData()
      formData.append("file", this.file);

      await this.uploadSurveySmsBlast(formData)
        .then(() => {
          if(this.smsBlastUpload.file_uploaded.errors.length == 0) {
            this.step++;
          }
        })
        .finally(() => {
          this.loadingUpload = false;
        })
    },
    goToUploadStep() {
      const isValid = this.$refs.info_form.validate();
      if (isValid) this.step++;
    },
    async goToMessageStep () {
      const isValid = this.$refs.file_form.validate();
      if (isValid) {
        await this.uploadSurveyRecipients();
      }
    },
    goToReviewStep() {
      const isValid = this.$refs.message_form.validate();

      if (isValid) {
        const surveyCategory = (this.survey.category?.name)?.toLowerCase() ?? null
        if (this.sendType.value === 'dealer_send') {
          this.infobipSender = this.infobipSenderDetails?.dealer ?? this.infobipSenderDetails[surveyCategory]
        } else {
          this.infobipSender = this.infobipSenderDetails[surveyCategory]
        }

        this.step++
      }
    },
    async submit() {
      this.loadingSave = true;

      this.form.message = this.message;
      this.form.file_id = this.smsBlastUpload.id
      this.form.survey_id = this.survey.id

      if (this.form.sending_method === 'viber') {
        this.form.viberFile = this.viberFile;
        this.form.viber_message_type = this.viberSendType.value
      } else {
        this.form.sending_method = null
        this.form.viber_video_duration = null
        this.form.viber_message_type = null
        this.form.viber_video_url = null
      }

      // this.form.viber_button_label = this.form.sending_method === 'viber' ? this.form.viber_button_label : null

      if (this.sendType.value === 'dealer_send') {
        this.form.dealer_id = this.dealer.value
      }
      
      this.form.type = this.sendType.value

      const id = this.$route.params.id ? this.$route.params.id : null;

      let formData = new FormData();

      Object.entries(this.form).forEach(item => {
        const [ key, value ] = item;
        
        if (value) {
          formData.append(key, value);
        }
      })

      await this.save({
        id,
        // data: this.form,
        data: formData
      })
        .then((data) => {
          this.$router.push({ name: "text-blast.index" });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    insertTextToMessage(item) {
      let textarea = this.$refs.inp_sms_message.$el;
      let start = this.message.length;
      let end = this.message.length;

      this.message = `${this.message.slice(0, start)}{${item}}${this.message.slice(end)}`;

      this.$nextTick(() => {
        textarea.selectionStart = start + "inserted text".length;
        textarea.selectionEnd = start + "inserted text".length;
      });
    }
  },
  mounted() {
    if (this.form.sending_method === 'viber') {
      this.viberSendType = this.viberSendTypes[0]
    }
    this.getSurveys();
    this.getDealers({
      order_by: 'name',
      sort: 'ASC'
    });
  }
};
</script>